.image-modal
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(0, 0, 0, 0.8)
  display: flex
  justify-content: center
  align-items: center
  z-index: 999


.modal-content
  width: 50%
  height: 80%
  overflow: hidden


.modal-content img
  width: 50%
  height: 80%
  object-fit: contain
  margin: auto

.close-button
  position: absolute
  top: 0
  right: 10%
  background-color: transparent
  border: none
  color: white
  cursor: pointer
@media(max-width: 1024px)
  .modal-content
    width: 70%
    height: auto
    overflow: hidden


  .modal-content img
    width: 70%
    height: auto
    object-fit: contain
    margin: auto
