.doctors {
  background-color: #DDF5FF;
  height: auto;
  position: relative;
}

.water_reviews {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-top: 115px;
  padding-bottom: 130px;
}

.water_left {
  width: 500px;
  height: auto;
  z-index: -1;
}

.doctors_text_block {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  width: 413.252px;
  height: 245.026px;
  flex-shrink: 0;
  background: #1A92FD;
  left: 16%;
  top: 35%;
}

.doctors_text {
  width: 365px;
  color: #FFF;
  text-align: center;
  font-family: "Druk Cyr", sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 63px;
  letter-spacing: 3.088px;
}

.doctors_reviews {
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  padding-left: 15%;
  width: 62.5%;
  height: 597.326px;
  background: #ffffff;
}

.name_post {
  margin-left: 20px;
  text-align: left;
}

.doctor_info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 50px;
}

.doctor_img {
  width: 152px;
  height: 152px;
  margin-right: 15px;
}

.doctor_name {
  color: #04549B;
  font-family: Comfortaa, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.doctor_post {
  color: #04549B;
  font-family: Comfortaa, sans-serif;
  font-size: 18.285px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.doctors_reviews__text {
  color: #04549B;
  font-family: Comfortaa, sans-serif;
  font-size: 20.381px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-right: 30px;
  text-align: left;
}

.slick-prev {
  top: 430px;
  left: -100px;
  width: 53px;
  height: 53px;
}

.slick_next {
  top: 430px;
  right: 80px;
  width: 53px;
  height: 53px;
}

.slick-next:before, .slick-prev:before {
  font-size: 53px;
  line-height: 1;
  opacity: 1;
  color: #04549B;
}

@media (max-width: 1399px) {
  .water_left {
    width: 198px;
    height: 195px;
    z-index: -1;
  }
  .doctors_text_block {
    width: 246px;
    height: 145.026px;
    left: 10%;
    top: 37%;
  }
  .doctors_text {
    width: 217px;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 1.8px;
  }
  .doctors_reviews {
    padding-top: 4%;
    padding-left: 20%;
    width: 68.5%;
    height: 355px;
  }
  .doctor_img {
    width: 90px;
    height: 90px;
    margin-right: 15px;
  }
  .doctor_name {
    font-size: 18px;
  }
  .doctor_post {
    font-size: 11px;
  }
  .doctors_reviews__text {
    font-size: 13px;
  }
  .doctor_info {
    padding-left: 15px;
    margin-bottom: 20px;
    margin-right: 30px;
  }
}
@media (max-width: 720px) {
  .water_reviews {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .doctors_reviews {
    padding-top: 8%;
    padding-left: 10%;
    padding-right: 2%;
    width: 90%;
    height: 380px;
    margin-left: 20px;
    margin-top: 30px;
  }
  .doctors_text_block {
    width: 246px;
    height: 145.026px;
    left: 20%;
    top: 14%;
  }
  .doctors_reviews__text {
    font-size: 12px;
  }
  .water_left {
    width: 210px;
    height: 195px;
    z-index: -1;
  }
}

