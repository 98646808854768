.accordion {
  margin: auto;
}
.accordion__container:hover {
  cursor: pointer;
}
.accordion-wrapper {
  justify-content: space-between;
  align-items: center;
  width: 70%;
  height: 123px;
  flex-shrink: 0;
  border-radius: 48.761px;
  background: var(--4, #FDFCFC);
  box-shadow: 0 24.38066px 24.38066px 0 rgba(0, 0, 0, 0.05);
  margin: auto;
  margin-bottom: 32px;
}
.accordion-label {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.accordion-content {
  margin: -20px auto 20px auto;
  width: 90%;
  text-align: start;
  color: #04549B;
  font-family: Comfortaa, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: pre-line;
  border-radius: 28.761px;
  padding: 0 20px 20px 20px;
  background: var(--4, #FDFCFC);
}
.accordion-title {
  margin: auto;
  align-items: center;
  display: flex;
  text-align: start;
  margin-left: 45px;
  width: 100%;
  color: #04549B;
  font-family: Comfortaa, serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.accordion-symbol {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 10%;
  color: #04549B;
  font-size: 60px;
  margin-top: -10px;
}

@media (max-width: 1399px) {
  .accordion-title {
    font-size: 17px;
    margin-left: 33px;
  }
  .accordion-wrapper {
    border-radius: 18.78px;
    width: 500px;
    height: 71px;
    margin-bottom: 18px;
  }
  .accordion-symbol {
    margin-right: 10px;
    height: 71px;
    font-size: 39px;
  }
  .accordion-content {
    border-radius: 18.78px;
    width: 500px;
    margin: -5px auto 10px auto;
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .accordion-title {
    font-size: 13px;
    margin-left: 23px;
  }
  .accordion-wrapper {
    border-radius: 12.069px;
    width: 90%;
    height: 46px;
  }
  .accordion-symbol {
    height: 46px;
    margin-top: -5px;
    font-size: 30px;
  }
  .accordion-content {
    width: 90%;
    font-size: 12px;
    margin: -10px auto 10px auto;
    border-radius: 12.069px;
  }
}

