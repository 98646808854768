.wrap
  background-color: rgba(255, 255, 255, 1)
  height: 200px
  display: flex
  align-items: center
  justify-content: space-between
  &_link
    color: #04549B
    font-family: 'Comfortaa', sans-serif
    font-size: 20px
    font-style: normal
    font-weight: 700
    line-height: normal
    text-decoration: none
    cursor: pointer
    &:nth-of-type(1)
      padding-left: 72px
    &:nth-of-type(2)
      padding-left: 80px
      padding-right: 80px
    &__tel
      @extend .wrap_link
      padding-left: 150px
    &__inst
      padding-left: 52px
      &>img
        width: 42px
        height: 42px



@media (max-width: 1399px)

  .wrap
    height: 105.61px
    &_img
      width: 72.92px
      height: 67.9px
    &_link
      font-size: 13px
      line-height: 14.5px
      &:nth-of-type(1)
        padding-left: 50px
      &:nth-of-type(2)
        padding-left: 50px
        padding-right: 50px
      &__tel
        padding-left: 50px
      &__inst
        padding-left: 30px
        &>img
          width: 32px
          height: 32px



@media (max-width: 767px)

  .wrap
    height: 392px
    flex-direction: column
    align-items: center
    &_img
      width: 118.63px
      height: 110.45px
      margin-top: 26px
    &_link
      font-size: 13px
      line-height: 14.5px
      &:nth-of-type(1)
        padding-left: 0
      &:nth-of-type(2)
        padding-left: 0
        padding-right: 0
      &__tel
        padding-left: 0
      &__inst
        padding-left: 10px
        margin-bottom: 39px
        &>img
          width: 22px
          height: 22px
