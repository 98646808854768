.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.modal-content {
  background: #C9EDFC !important;
  width: 1200px !important;
  height: 50vh !important;
}

.popup_bg {
  position: relative;
}

.curs_popup_drop_img {
  position: absolute;
  width: 126px !important;
  height: 80px;
  top: 5%;
  left: 2%;
}

.instructor_title_popup {
  color: #1A92FD;
  text-align: center;
  font-family: "Druk Cyr", sans-serif;
  font-size: 70px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 3.45px;
  padding-top: 30px;
  margin-bottom: 50px;
}

.button_close {
  position: absolute;
  top: 10%;
  left: 85%;
  border-radius: 23.39px;
  background: linear-gradient(94deg, #57C2FE 11.04%, #1A92FD 100.02%);
  color: #FFF;
  border: none !important;
  font-size: 15px !important;
  text-align: center;
  font-family: "Comfortaa", sans-serif !important;
  padding-right: 20px !important;
  padding-left: 20px !important;
}
.button_close:hover {
  background: linear-gradient(94deg, #1A92FD 100.02%, #57C2FE 11.04%);
}

.wrap {
  background-color: #c9edfc;
}

.box_popup {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 76px;
  padding-bottom: 180px;
}
.box_popup_title {
  color: #1A92FD;
  text-align: center;
  font-family: "Druk Cyr", sans-serif;
  font-size: 109px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 5.45px;
  margin-bottom: 20px;
}
.box_popup_desc {
  color: #04549B;
  text-align: center;
  font-family: "Comfortaa", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 63px;
}
.box_popup_btnsBox {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 65px;
}
.box_popup_form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.box_popup_form__inp {
  width: 30%;
  height: 70px;
  flex-shrink: 0;
  border-radius: 63.39px;
  border: 2.438px solid #1A92FD;
  background-color: transparent;
  color: #848D95;
  font-family: "Comfortaa", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.box_popup_form__inp:focus {
  border-color: #1A92FD;
}
.box_popup_form__btn {
  width: 30%;
  height: 70px;
  flex-shrink: 0;
  border-radius: 63.39px;
  background: linear-gradient(94deg, #57C2FE 11.04%, #1A92FD 100.02%);
  box-shadow: 0px 6.09517px 0px 0px #1D96FF;
  color: #FFF;
  text-align: center;
  font-family: "Comfortaa", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
}
.box_popup_form__btn:hover {
  background: linear-gradient(94deg, #1A92FD 100.02%, #57C2FE 11.04%);
}
.box_popup_form__btn:hover:active {
  transform: scale(0.95);
  box-shadow: 0px 2px 0px 0px #1D96FF;
  background: linear-gradient(94deg, #1A92FD 11.04%, #57C2FE 100.02%);
}

@media (max-width: 1320px) {
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .modal-content {
    background: #C9EDFC !important;
    width: 695px !important;
    height: 333px !important;
  }
  .box_popup {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 76px;
    padding-bottom: 180px;
  }
  .box_popup_title {
    color: #1A92FD;
    text-align: center;
    font-family: "Druk Cyr", sans-serif;
    font-size: 109px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 5.45px;
    margin-bottom: 20px;
  }
  .box_popup_desc {
    color: #04549B;
    text-align: center;
    font-family: "Comfortaa", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 63px;
  }
  .box_popup_btnsBox {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 65px;
  }
  .box_popup_form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .box_popup_form__inp {
    width: 195px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 63.39px;
    border: 2.438px solid #1A92FD;
    background-color: transparent;
    color: #848D95;
    font-family: "Comfortaa", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
  .box_popup_form__inp:focus {
    border-color: #1A92FD;
  }
  .box_popup_form__btn {
    width: 195px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 63.39px;
    background: linear-gradient(94deg, #57C2FE 11.04%, #1A92FD 100.02%);
    box-shadow: 0px 6.09517px 0px 0px #1D96FF;
    color: #FFF;
    text-align: center;
    font-family: "Comfortaa", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
  }
  .box_popup_form__btn:active {
    transform: scale(0.95);
    box-shadow: 0px 2px 0px 0px #1D96FF;
    background: linear-gradient(94deg, #1A92FD 11.04%, #57C2FE 100.02%);
  }
  .button_close {
    position: absolute;
    top: 3%;
    left: 85%;
    border-radius: 23.39px;
    background: linear-gradient(94deg, #57C2FE 11.04%, #1A92FD 100.02%);
    color: #FFF;
    border: none !important;
    font-size: 10px !important;
    text-align: center;
    font-family: "Comfortaa", sans-serif !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .instructor_title_popup {
    color: #1A92FD;
    text-align: center;
    font-family: "Druk Cyr", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.45px;
    padding-top: 10px;
    margin-bottom: 30px;
  }
  .curs_popup_drop_img {
    display: none;
    position: absolute;
    width: 56px !important;
    height: 80px;
    top: 18%;
    left: 2%;
  }
}
@media (max-width: 720px) {
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .modal-content {
    background: #C9EDFC !important;
    width: 300px !important;
    height: 359px !important;
  }
  .box_popup {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 76px;
    padding-bottom: 180px;
  }
  .box_popup_title {
    color: #1A92FD;
    text-align: center;
    font-family: "Druk Cyr", sans-serif;
    font-size: 109px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 5.45px;
    margin-bottom: 20px;
  }
  .box_popup_desc {
    color: #04549B;
    text-align: center;
    font-family: "Comfortaa", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 63px;
  }
  .box_popup_btnsBox {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 65px;
  }
  .box_popup_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .box_popup_form__inp {
    width: 100%;
    height: 50px;
    flex-shrink: 0;
    border-radius: 63.39px;
    border: 2.438px solid #1A92FD;
    background-color: transparent;
    color: #848D95;
    font-family: "Comfortaa", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-bottom: 10px;
  }
  .box_popup_form__inp:focus {
    border-color: #1A92FD;
  }
  .box_popup_form__btn {
    width: 100%;
    height: 50px;
    flex-shrink: 0;
    border-radius: 63.39px;
    background: linear-gradient(94deg, #57C2FE 11.04%, #1A92FD 100.02%);
    box-shadow: 0px 6.09517px 0px 0px #1D96FF;
    color: #FFF;
    text-align: center;
    font-family: "Comfortaa", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    margin-bottom: 30px;
  }
  .box_popup_form__btn:active {
    transform: scale(0.95);
    box-shadow: 0px 2px 0px 0px #1D96FF;
    background: linear-gradient(94deg, #1A92FD 11.04%, #57C2FE 100.02%);
  }
  .button_close {
    position: absolute;
    top: 3%;
    left: 85%;
    border-radius: 23.39px;
    background: linear-gradient(94deg, #57C2FE 11.04%, #1A92FD 100.02%);
    color: #FFF;
    border: none !important;
    font-size: 10px !important;
    text-align: center;
    font-family: "Comfortaa", sans-serif !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
    display: none !important;
  }
  .instructor_title_popup {
    color: #1A92FD;
    text-align: center;
    font-family: "Druk Cyr", sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: 55px;
    letter-spacing: 1.9px;
    padding-top: 50px;
    margin-bottom: 15px;
  }
  .curs_popup_drop_img {
    display: none;
    position: absolute;
    width: 56px !important;
    height: 80px;
    top: 18%;
    left: 2%;
  }
}

