.futureTeachers
  background: #DDF5FF
  &-thanks
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between
    height: 100%

    &__button
      border: none
      position: absolute
      bottom: 10%
  &-wrapper
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
  &-title
    color: #1A92FD
    text-align: center
    font-family: Druk Cyr, serif
    font-size: 109px
    font-style: normal
    font-weight: 500
    line-height: normal
    letter-spacing: 5.45px
    margin: 33px auto 45px auto

  &-text
    color: #04549B
    text-align: center
    font-family: Comfortaa, serif
    font-size: 30px
    font-style: normal
    font-weight: 400
    line-height: normal
    margin-bottom: 69px
  &-button
    width: 462.014px
    height: 84.113px
    border-radius: 63.39px
    border: none
    background: linear-gradient(94deg, #57C2FE 11.04%, #1A92FD 100.02%)
    box-shadow: 0 6.09517px 0 0 #1D96FF
    color: #FFF
    text-align: center
    font-family: Comfortaa, serif
    font-size: 38px
    font-style: normal
    font-weight: 400
    line-height: normal
    margin-bottom: 150px
    &:hover
      background: linear-gradient(94deg, #1A92FD 100.02%, #57C2FE 11.04%)
    &:active
      transform: scale(0.95)
      box-shadow: 0px 2px 0px 0px #1D96FF
      background: linear-gradient(94deg, #1A92FD 11.04%, #57C2FE 100.02%)
@media(max-width: 1399px)
  .futureTeachers
    &-title

      font-size: 59px
      letter-spacing: 2.95px
      margin: 33px auto 20px auto
    &-text
      font-size: 17px
      width: 540.95px
      margin-bottom: 32px
    &-button
      width: 240.916px
      height: 43.861px
      font-size: 20px
      margin-bottom: 82px
      border-radius: 34.098px
      background: linear-gradient(94deg, #57C2FE 11.04%, #1A92FD 100.02%)
      box-shadow: 0 3.27863px 0 0 #1D96FF
@media (max-width: 767px)
  .futureTeachers
    &-title
      font-size: 50px
      letter-spacing: 2.5px
    &-text
      width: 277px
      font-size: 14px
    &-button
      width: 274.879px
      height: 47px
      border-radius: 35.75px
      background: linear-gradient(94deg, #57C2FE 11.04%, #1A92FD 100.02%)
      box-shadow: 0 3.43751px 0 0 #1D96FF
