.wrap {
  background-color: #c9edfc;
}

.box {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 76px;
  padding-bottom: 180px;
}
.box_title {
  color: #1A92FD;
  text-align: center;
  font-family: "Druk Cyr", sans-serif;
  font-size: 109px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 5.45px;
  margin-bottom: 20px;
}
.box_desc {
  color: #04549B;
  text-align: center;
  font-family: "Comfortaa", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 63px;
}
.box_btnsBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 65px;
}
.box_form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box_form__inp {
  width: 416.528px;
  height: 84.113px;
  flex-shrink: 0;
  border-radius: 63.39px;
  border: 2.438px solid #1A92FD;
  background-color: transparent;
  color: #848D95;
  font-family: "Comfortaa", sans-serif;
  font-size: 31px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.box_form__inp:focus {
  border-color: #1A92FD;
}
.box_form__btn {
  width: 416.528px;
  height: 84.113px;
  flex-shrink: 0;
  border-radius: 63.39px;
  background: linear-gradient(94deg, #57C2FE 11.04%, #1A92FD 100.02%);
  box-shadow: 0px 6.09517px 0px 0px #1D96FF;
  color: #FFF;
  text-align: center;
  font-family: "Comfortaa", sans-serif;
  font-size: 31px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
}
.box_form__btn:active {
  transform: scale(0.95);
  box-shadow: 0px 2px 0px 0px #1D96FF;
  background: linear-gradient(94deg, #1A92FD 11.04%, #57C2FE 100.02%);
}

@media (max-width: 1399px) {
  .box {
    padding-top: 58px;
    padding-bottom: 67px;
  }
  .box_title {
    font-size: 59px;
    letter-spacing: 2.95px;
    margin-bottom: 10px;
  }
  .box_desc {
    font-size: 17px;
    margin-bottom: 53px;
  }
  .box_btnsBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 35px;
  }
  .box_form__inp {
    width: 223.691px;
    height: 45.263px;
    border-radius: 34.111px;
    border: 1.312px solid #1A92FD;
    font-size: 17px;
  }
  .box_form__btn {
    width: 223.691px;
    height: 45.263px;
    border-radius: 34.111px;
    font-size: 17px;
  }
}
@media (max-width: 767px) {
  .box {
    padding-top: 50px;
    padding-bottom: 60px;
  }
  .box_title {
    font-size: 50px;
    letter-spacing: 2.5px;
    margin-bottom: 20px;
  }
  .box_desc {
    font-size: 14px;
    margin-bottom: 29px;
  }
  .box_btnsBox {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 15px;
  }
  .box_form {
    flex-direction: column;
  }
  .box_form__inp {
    width: 274.691px;
    height: 45.263px;
    border-radius: 34.111px;
    border: 1.312px solid #1A92FD;
    font-size: 17px;
    margin-bottom: 20px;
  }
  .box_form__btn {
    width: 274.691px;
    height: 47px;
    border-radius: 35.75px;
    font-size: 17px;
  }
}

