@font-face {
  font-family: 'Comfortaa';
  src: url('Comfortaa-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Comfortaa-Bold';
  src: url('Comfortaa-Bold.ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Comfortaa-SemiBold';
  src: url('Comfortaa-SemiBold.ttf');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Comfortaa-Medium';
  src: url('Comfortaa-Medium.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Comfortaa-Light';
  src: url('Comfortaa-Light.ttf');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
