.wrap {
  background-color: rgb(255, 255, 255);
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrap_link, .wrap_link__tel {
  color: #04549B;
  font-family: "Comfortaa", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  cursor: pointer;
}
.wrap_link:nth-of-type(1), .wrap_link__tel:nth-of-type(1) {
  padding-left: 72px;
}
.wrap_link:nth-of-type(2), .wrap_link__tel:nth-of-type(2) {
  padding-left: 80px;
  padding-right: 80px;
}
.wrap_link__tel {
  padding-left: 150px;
}
.wrap_link__inst {
  padding-left: 52px;
}
.wrap_link__inst > img {
  width: 42px;
  height: 42px;
}

@media (max-width: 1399px) {
  .wrap {
    height: 105.61px;
  }
  .wrap_img {
    width: 72.92px;
    height: 67.9px;
  }
  .wrap_link, .wrap_link__tel {
    font-size: 13px;
    line-height: 14.5px;
  }
  .wrap_link:nth-of-type(1), .wrap_link__tel:nth-of-type(1) {
    padding-left: 50px;
  }
  .wrap_link:nth-of-type(2), .wrap_link__tel:nth-of-type(2) {
    padding-left: 50px;
    padding-right: 50px;
  }
  .wrap_link__tel {
    padding-left: 50px;
  }
  .wrap_link__inst {
    padding-left: 30px;
  }
  .wrap_link__inst > img {
    width: 32px;
    height: 32px;
  }
}
@media (max-width: 767px) {
  .wrap {
    height: 392px;
    flex-direction: column;
    align-items: center;
  }
  .wrap_img {
    width: 118.63px;
    height: 110.45px;
    margin-top: 26px;
  }
  .wrap_link, .wrap_link__tel {
    font-size: 13px;
    line-height: 14.5px;
  }
  .wrap_link:nth-of-type(1), .wrap_link__tel:nth-of-type(1) {
    padding-left: 0;
  }
  .wrap_link:nth-of-type(2), .wrap_link__tel:nth-of-type(2) {
    padding-left: 0;
    padding-right: 0;
  }
  .wrap_link__tel {
    padding-left: 0;
  }
  .wrap_link__inst {
    padding-left: 10px;
    margin-bottom: 39px;
  }
  .wrap_link__inst > img {
    width: 22px;
    height: 22px;
  }
}

