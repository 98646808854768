.cleaning_bg
  background-image: url("../../Assets/Images/cleaning_bg.webp")
  width: 100%
  height: auto
  background-size: cover
  background-repeat: no-repeat

.cleaning_heading
  color: #1A92FD
  text-align: center
  font-family: 'Druk Cyr', sans-serif
  font-size: 109px
  font-style: normal
  font-weight: 500
  line-height: normal
  letter-spacing: 5.45px
  padding-top: 70px
  margin-bottom: 50px

.cleaning_text
  color: #FFF
  text-align: center
  font-family: 'Comfortaa', sans-serif
  font-size: 37px
  font-style: normal
  font-weight: 400
  line-height: normal
  padding-bottom: 115px
  margin: 0

@media (max-width: 1320px)
  .cleaning_bg
    background-image: url("../../Assets/Images/cleaning_bg_tablet.webp")

  .cleaning_heading
    font-size: 59px
    padding-top: 40px
    margin-bottom: 30px
    letter-spacing: 2.5px

  .cleaning_text
    font-size: 20px
    padding-bottom: 60px

@media (max-width: 720px)

  .cleaning_heading
    font-size: 53px
    padding-top: 60px
    margin-bottom: 25px

  .cleaning_bg
    background-image: url("../../Assets/Images/cleaning_bg_mobile.webp")

  .cleaning_text
    line-height: 26px
    padding-bottom: 80px
