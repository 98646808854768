.box
  background: #C9EDFC

.wrap
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
  position: relative
  &_left
    display: flex
    flex-direction: column
    align-items: flex-start
    &__title
      color: #1A92FD
      text-align: center
      font-family: 'Druk Cyr', sans-serif
      font-size: 109px
      font-style: normal
      font-weight: 500
      line-height: normal
      letter-spacing: 5.45px
      margin-bottom: 44px
    &__description
      color: #04549B
      font-family: 'Comfortaa', sans-serif
      font-size: 30px
      font-style: normal
      font-weight: 400
      line-height: normal
      width: 709.501px
  &_right
    width: 563.818px
    height: 718.769px
    flex-shrink: 0
    margin-left: 22px
  &_img1
    position: absolute
    width: 225.472px
    height: 225.521px
    flex-shrink: 0
    top: 0
    left: 40%
  &_img2
    position: absolute
    width: 121.314px
    height: 143.846px
    flex-shrink: 0
    bottom: 10%
    left: 30%




@media (max-width: 1399px)

  .wrap
    justify-content: space-around
    &_left
      &__title
        font-size: 59px
        letter-spacing: 2.95px
        margin-bottom: 22px
      &__description
        font-size: 17px
        width: 409px
    &_right
      width: 284px
      height: 364px
    &_img1
      width: 200px
      height: 200px
      top: 0
      left: 40%
    &_img2
      width: 100px
      height: 121px
      bottom: 0%
      left: 55%

@media (max-width: 767px)

  .wrap
    flex-direction: column
    justify-content: space-around
    &_left
      &__title
        width: 100%
        font-size: 50px
        letter-spacing: 2.5px
        margin-bottom: 21px
        padding-top: 65px
      &__description
        font-size: 14px
        width: 321px
        text-align: center
        line-height: 20px
    &_right
      width: 284px
      height: 364px
    &_img1
      display: none
    &_img2
      width: 100px
      height: 121px
      bottom: 40%
      left: 15%

