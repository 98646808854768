@import "../../Assets/Fonts/fonts.css";
.box {
  background: #C9EDFC;
}

.wrap {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 250px;
}
.wrap_title {
  color: #1A92FD;
  text-align: center;
  font-family: "Druk Cyr", sans-serif;
  font-size: 109px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 5.45px;
  margin-top: 97px;
}
.wrap_description {
  color: #04549B;
  text-align: center;
  font-family: "Comfortaa", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 45px;
}
.wrap_carousele {
  position: relative;
  height: 400px;
  width: 100%;
  margin-top: 100px;
}

.dr1 {
  width: 158.474px;
  height: 225.521px;
  flex-shrink: 0;
  position: absolute;
  top: 100px;
  right: 0;
}

.dr2 {
  width: 181.636px;
  height: 258.435px;
  flex-shrink: 0;
  position: absolute;
  top: 400px;
  left: -15%;
}

@media (max-width: 1399px) {
  .box {
    background: #C9EDFC;
  }
  .wrap {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
  }
  .wrap_title {
    color: #1A92FD;
    text-align: center;
    font-family: "Druk Cyr", sans-serif;
    font-size: 59px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.95px;
    margin-top: 65px;
  }
  .wrap_description {
    color: #04549B;
    text-align: center;
    font-family: "Comfortaa", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 29px;
    margin-bottom: 50px;
  }
  .wrap_carousele {
    position: relative;
    height: 300px;
    width: 100%;
    margin-top: 0px;
  }
  .dr1 {
    width: 112px;
    height: 109px;
    flex-shrink: 0;
    position: absolute;
    top: 37px;
    right: 0;
  }
  .dr2 {
    width: 72px;
    height: 102px;
    flex-shrink: 0;
    position: absolute;
    top: 37px;
    left: 15%;
  }
}
@media (max-width: 767px) {
  .box {
    background: #C9EDFC;
  }
  .wrap {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
  }
  .wrap_title {
    color: #1A92FD;
    text-align: center;
    font-family: "Druk Cyr", sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.5px;
    margin-top: 31px;
  }
  .wrap_description {
    color: #04549B;
    text-align: center;
    font-family: "Comfortaa", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 14px;
    margin-bottom: 33px;
  }
  .wrap_carousele {
    position: relative;
    height: 200px;
    width: 100%;
    margin-top: 0px;
  }
  .dr1 {
    width: 72px;
    height: 50px;
    flex-shrink: 0;
    position: absolute;
    top: 37px;
    right: 0;
  }
  .dr2 {
    width: 50px;
    height: 60px;
    flex-shrink: 0;
    position: absolute;
    top: 37px;
    left: 15%;
  }
}

