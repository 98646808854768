.students_bg {
  padding-bottom: 200px;
  background-color: #C9EDFC;
}

.students_title {
  color: #1A92FD;
  text-align: center;
  font-family: "Druk Cyr", sans-serif;
  font-size: 109px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 5.45px;
  padding-top: 130px;
  margin-bottom: 100px;
}

.students_block {
  display: flex;
  justify-content: space-evenly;
}

.students_block_slider {
  width: 100%;
}

.students {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  width: 100% !important;
  height: 535px;
}

.students_img {
  width: 329px;
  height: auto;
}

.student_info {
  align-items: center;
  width: 329px;
  height: auto;
  border-radius: 22.34px;
  background: #FFF;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 15px;
  transform: translateY(-35%);
}

.students_name {
  color: #04549B;
  text-align: center;
  font-family: "Comfortaa", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.student_year {
  color: #04549B;
  text-align: center;
  font-family: "Comfortaa", sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.student_about {
  color: #04549B;
  text-align: center;
  font-family: "Comfortaa", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 1320px) {
  .students_bg {
    padding-bottom: 100px;
  }
  .students_title {
    font-size: 59px;
    margin-bottom: 45px;
    padding-top: 50px;
  }
  .students {
    height: auto;
  }
  .students_img {
    width: 80%;
  }
  .student_info {
    width: 80%;
    transform: translateY(-30%);
  }
  .students_name {
    font-size: 16px;
  }
  .student_year {
    font-size: 13px;
  }
  .student_about {
    font-size: 13px;
  }
}
@media (max-width: 720px) {
  .students_title {
    font-size: 50px;
    line-height: 53px;
    margin-bottom: 35px;
    letter-spacing: 2.5px;
  }
  .students_bg {
    padding-bottom: 70px;
  }
  .students_img {
    width: 70%;
  }
  .student_info {
    width: 70%;
    transform: translateY(-35%);
  }
}

