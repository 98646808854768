.header
  position: relative
  &-navbar
    display: flex
    width: 100%
    align-items: center
    justify-content: space-between
    background: inherit
    margin: 33px 0
  &-logo
    margin-right: 35px
  &-wrapper
    display: flex
    align-items: center
    justify-content: space-between
  &-menu
    display: flex
    align-items: center
  &-link, &-phone
    color: #FFF
    margin: 11px
    text-decoration: none
    font-family: Comfortaa, serif
    font-size: 17px
    font-style: normal
    font-weight: 700
    line-height: normal
  &-button
    width: 204px
    height: 52px
    border-radius: 18.01px
    background: linear-gradient(94deg, #E1F4FE 11.04%, #ADD9FF 100.02%)
    box-shadow: 0px 1.73176px 0px 0px #89C1F2
    border: none
    &__link
      color: #04549B
      font-family: Comfortaa, serif
      font-size: 23.162px
      font-style: normal
      font-weight: 400
      line-height: normal
      text-decoration: none
      display: flex
      align-items: center
      justify-content: center
  &-button:hover
    scale: 1.1
  &-block
    margin-top: 259px
    margin-bottom: 216px

    &__link
      font-size: 31px
      color: #FFF
      text-decoration: none
    &__title
      font-family: Druk Cyr, serif
      color: #FFF
      font-size: 105px
      font-style: normal
      font-weight: 500
      line-height: normal
      letter-spacing: 5.25px
      margin-bottom: 25px
    &__text
      font-family: Comfortaa, serif
      color: #FFF
      font-size: 33px
      font-style: normal
      font-weight: 400
      line-height: normal
      margin-bottom: 57px
    &__button
      width: 351px
      height: 69px
      border-radius: 52px
      background: linear-gradient(94deg, #57C2FE 11.04%, #1A92FD 100.02%)
      box-shadow: 0px 5px 0px 0px #1D96FF

.header-logo_mobile
    position: absolute
    display: none
#background-video
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 945px
  z-index: -1
  object-fit: cover
@media (max-width: 1399px)
  .header
    &-navbar
      margin: 19px 0
    &-logo
      width: 58px
      height: 54px
      margin-right: 24px
    &-link, &-phone
      font-size: 11px
      font-style: normal
      font-weight: 700
      margin: 0 5px
      white-space: nowrap
    &-button
      width: 96.43px
      height: 27px
      &__link
        font-size: 13px
        font-style: normal
        font-weight: 400
    &-block
      margin-top: 130px
      &__title
        font-size: 68px
        margin-bottom: 8px
        letter-spacing: 3.4px
      &__text
        font-size: 22px
        margin-bottom: 28px
      &__link
        font-size: 20px
      &__button
        width: 226.273px
        height: 44.481px
  #background-video
    height: 650px
@media (max-width: 767px)
  .header
    &-navbar
      margin: 26px 0
    &-logo
      margin: 0
      display: none
    &-link, &-phone
      display: none
    &-button
      width: 96.43px
      height: 27px
      &__link
        font-size: 13px
    &-block
      margin-top: 160px
      margin-bottom: 218px
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      &__title
        font-size: 58px
        letter-spacing: 2.9px
        margin-bottom: 11px
      &__text
        font-size: 19px
        margin-bottom: 27px
      &__link
        font-size: 20px
      &__button
        width: 226.273px
        height: 44.481px
  #background-video
    height: 620px

  .header-logo_mobile
    position: absolute
    display: block
    top: 8%
    
