.instructor_bg_color {
  background: #DDF5FF;
  padding-bottom: 105px;
  position: relative;
}

.curs_drop_img {
  display: none;
}

.curs_drop_img2 {
  display: none;
}

.curs_drop_img3 {
  display: none;
}

.instructor_title {
  color: #1A92FD;
  text-align: center;
  font-family: "Druk Cyr", sans-serif;
  font-size: 109px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 5.45px;
  padding-top: 80px;
  margin-bottom: 50px;
}

.instructor_sub_title {
  color: #04549B;
  text-align: center;
  font-family: "Comfortaa", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 90px;
}

.curs_block {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30.671px;
  background: #EEFAFF;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 55px;
}

.curs_number {
  color: #1A92FD;
  text-align: center;
  font-family: "Druk Cyr", sans-serif;
  font-size: 75px;
  font-style: normal;
  font-weight: 500;
  line-height: 93.6%;
  letter-spacing: 3.75px;
}

.curs_text {
  color: #04549B;
  font-family: "Comfortaa", sans-serif;
  font-size: 31px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 20px;
  margin-bottom: 0px;
}

.curs_button {
  display: flex;
  justify-content: center;
  margin-top: 88px;
}

.instructor_button {
  border-radius: 63.39px;
  background: linear-gradient(94deg, #57C2FE 11.04%, #1A92FD 100.02%);
  box-shadow: 0px 6.09517px 0px 0px #1D96FF;
  color: #FFF;
  text-align: center;
  font-family: "Comfortaa", sans-serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  width: 462px;
  height: 84px;
  padding: 20px 35px 20px 25px;
}
.instructor_button:hover {
  background: linear-gradient(94deg, #1A92FD 100.02%, #57C2FE 11.04%);
}
.instructor_button:active {
  transform: scale(0.95);
  box-shadow: 0px 2px 0px 0px #1D96FF;
  background: linear-gradient(94deg, #1A92FD 11.04%, #57C2FE 100.02%);
}

@media (max-width: 1320px) {
  .instructor_bg_color {
    padding-bottom: 58px;
  }
  .instructor_title {
    font-size: 59px;
    line-height: 93.6%;
    letter-spacing: 2.95px;
    margin-bottom: 20px;
  }
  .instructor_sub_title {
    font-size: 17px;
    margin-bottom: 45px;
  }
  .curs_block {
    border-radius: 16px;
    padding-top: 23px;
    padding-bottom: 23px;
    margin-bottom: 29px;
    height: 81px;
  }
  .curs_number {
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 93.6%;
    letter-spacing: 2px;
  }
  .curs_text {
    font-size: 16px;
    margin-left: 12px;
  }
  .curs_button {
    display: flex;
    justify-content: center;
    margin-top: 47px;
  }
  .instructor_button {
    border-radius: 34px;
    font-size: 20px;
    padding: 12px 19px 12px 19px;
    width: 248px;
    height: 45px;
  }
}
@media (max-width: 720px) {
  .instructor_bg_color {
    padding-bottom: 58px;
  }
  .curs_drop_img {
    display: block;
    position: absolute;
    width: 56px;
    height: 80px;
    top: 50%;
    left: 82%;
  }
  .curs_drop_img2 {
    display: block;
    position: absolute;
    width: 28px;
    height: 54px;
    top: 64%;
    left: 4%;
  }
  .curs_drop_img3 {
    display: block;
    position: absolute;
    width: 48px;
    height: 51px;
    top: 36%;
    left: 2%;
  }
  .instructor_title {
    font-size: 50px;
    line-height: 93.6%;
    letter-spacing: 2.95px;
    margin-bottom: 20px;
    padding-top: 40px;
  }
  .instructor_sub_title {
    font-size: 14px;
    margin-bottom: 36px;
  }
  .curs_block {
    border-radius: 16px;
    padding-top: 23px;
    padding-bottom: 23px;
    margin-bottom: 14px;
    height: 81px;
    margin-left: 40px;
    margin-right: 40px;
  }
  .curs_number {
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 93.6%;
    letter-spacing: 2px;
  }
  .curs_text {
    font-size: 16px;
    margin-left: 12px;
  }
  .curs_button {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .instructor_button {
    border-radius: 34px;
    font-size: 20px;
    padding: 12px 19px 12px 19px;
    width: 274px;
    height: 47px;
  }
}

