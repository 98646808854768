.yearlyTripsItem {
  width: 50%;
  margin: auto auto 175px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.yearlyTripsItem-wrapper {
  width: 93%;
  height: 133px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 29.076px;
  box-shadow: 0 2.79579px 0 0 #89C1F2;
  background: linear-gradient(94deg, #E1F4FE 11.04%, #CFE8FF 100.02%);
  margin: auto;
  position: absolute;
  bottom: -100px;
}
.yearlyTripsItem-title {
  color: #04549B;
  text-align: center;
  font-family: Comfortaa, serif;
  font-size: 31px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.yearlyTripsItem-text {
  color: #04549B;
  text-align: center;
  font-family: Comfortaa, serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.yearlyTripsItem-img {
  width: 93%;
  height: 350px;
}

@media (max-width: 1399px) {
  .yearlyTripsItem {
    margin: auto auto 100px auto;
  }
  .yearlyTripsItem-wrapper {
    height: 71px;
    width: 95%;
    border-radius: 15.625px;
    bottom: -50px;
  }
  .yearlyTripsItem-title {
    font-size: 17px;
    margin-bottom: 5px;
  }
  .yearlyTripsItem-text {
    font-size: 19px;
    margin-bottom: 0px;
  }
  .yearlyTripsItem-img {
    height: 188px;
  }
}
@media (max-width: 767px) {
  .yearlyTripsItem {
    width: 80%;
    height: 20vh;
    margin: auto auto 80px auto;
  }
  .yearlyTripsItem-wrapper {
    height: 50px;
    border-radius: 11.032px;
    width: 94%;
    bottom: -40px;
  }
  .yearlyTripsItem-title {
    font-size: 12.003px;
  }
  .yearlyTripsItem-text {
    font-size: 14px;
  }
  .yearlyTripsItem-img {
    height: 20vh;
  }
}

