.yearlyTripsList
  display: flex
  flex-wrap: wrap
  padding-bottom: 70px
.yearlytrips
  background-image: url("../../Assets/Images/YearlyTripsWEB.webp")
  background-size: cover
  background-position: top
  background-repeat: no-repeat
  &-raindropone,&-raindroptwo
    display: none
  &-title
    color: #1A92FD
    text-align: center
    font-family: Druk Cyr, serif
    font-size: 109px
    font-style: normal
    font-weight: 500
    line-height: normal
    margin-bottom: 42px
    letter-spacing: 5.45px
  &-text
    color: #04549B
    text-align: center
    font-family: Comfortaa, serif
    width: 70%
    margin: auto
    font-size: 30px
    font-style: normal
    font-weight: 400
    line-height: normal
    margin-bottom: 84px

@media (max-width: 1399px)
  .yearlyTripsList
    padding-bottom: 50px
  .yearlytrips
    &-title
      font-size: 59px
      letter-spacing: 2.95px
      margin-bottom: 19px
    &-text
      font-size: 17px
      margin-bottom: 51px
@media (max-width: 768px)
  .yearlytrips
    background: #C9EDFC
    position: relative
    &-title
      font-size: 50px
      letter-spacing: 2.5px
      margin-bottom: 27px
    &-text
      width: 272px
      font-size: 14px
      margin-bottom: 27px
    &-raindropone
      display: flex
      position: absolute
      width: 57.637px
      height: 81.5px
      left: 15%
      top: 33%
    &-raindroptwo
      width: 53.112px
      height: 50.399px
      right: 8%
      top: 72%
      display: flex
      position: absolute
  .yearlyTripsList
    flex-direction: column
