.children
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  background-image: url("../../Assets/Images/ChildrenBG.webp")
  background-size: cover
  background-repeat: no-repeat
  &-title
    padding-top: 80px
    margin-bottom: 54px
    color: #FFF
    text-align: center
    font-family: Comfortaa, serif
    font-size: 55px
    font-style: normal
    font-weight: 500
    line-height: normal
  &-text
    width: 93%
    color: #FFF
    text-align: center
    font-family: Comfortaa, serif
    font-size: 30px
    font-style: normal
    font-weight: 400
    line-height: normal
    margin-bottom: 57px
  &-carousel
    height: 294px
    padding-bottom: 70px
  &-wrapper
    margin: 0 10px
  &-img
    height: 294px
  .close-button
    top: 10px
@media (max-width: 1399px)
  .children
    &-title
      padding-top: 35px
      margin-bottom: 20.51px
      font-size: 29px
    &-text
      font-size: 17px
      margin-bottom: 27px
    &-img
      width: 182.74px
      height: 126.117px
    &-carousel
      height: 126.117px
      padding-bottom: 0
      margin: 0
      margin-bottom: 30px
  .rfm-marquee
    padding: 0
    margin: 0
    min-width: 80%
  .modal-content img
    width: 60%
  
@media (max-width: 767px)
  .children
    &-title
      font-size: 24.637px
      padding-top: 45px
      margin-bottom: 18px
    &-text
      font-size: 15px
      width: 90%
      margin: auto auto 52px auto
      font-weight: 400
      text-align: center
    &-img
      width: 300px
      height: 200px
    &-carousel
      height: 200px
  .rfm-marquee
    padding: 0
    margin: 0
    min-width: 80%
  .modal-content img
    width: 75%
  .close-button
    right: 3%
    width: 20px
    height: 20px
