.wrap
  width: 100%
  height: 211px
  background: #1A92FD
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  position: relative
  &_title
    color: #FFF
    text-align: center
    font-family: 'Gilroy', sans-serif
    font-size: 34.773px
    font-style: normal
    font-weight: 300
    line-height: 115.1%
  &_backImg
    position: absolute
    &__first
      bottom: 0
      left: 15%
    &__second
      bottom: 0
      left: 65%
    &__third
      top: 0
      left: 80%


@media (max-width: 1399px)

  .wrap
    width: 100%
    height: 152px
    &_title
      font-size: 15px
      line-height: 18.28px
    &_backImg
      &__first
        width: 121.81px
        height: 121.64px
        bottom: 0
        left: 15%
      &__second
        width: 70.23px
        height: 70.13px
        bottom: 0
        left: 65%
      &__third
        width: 79.11px
        height: 79px
        top: 0
        left: 80%


@media (max-width: 767px)

  .wrap
    width: 100%
    height: 149px
    &_title
      font-size: 18.71px
      line-height: 21.54px
    &_backImg
      &__first
        width: 91.7px
        height: 91.56px
        bottom: 0
        left: 0
      &__second
        width: 52.87px
        height: 52.79px
        bottom: 0
        left: 80%
      &__third
        width: 59.56px
        height: 59.47px
        top: 0
        left: 90%

  .logo
    width: 124.35px
    height: 41.95px
