.myimg {
  width: 100%;
  height: auto;
}

.numbers {
  position: absolute;
  bottom: -200px;
  left: 0%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.numbers_left {
  color: #04549B;
  text-align: center;
  font-family: "Comfortaa", sans-serif;
  font-size: 61px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.05px;
  margin-left: 90px;
  width: 40px !important;
}
.numbers_right {
  color: #04549B;
  text-align: center;
  font-family: "Comfortaa", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.6px;
  margin-right: 90px;
}
.numbers_line {
  width: 50px;
  height: 1px;
  background-color: #04549B;
  transform: rotate(110deg);
  margin: 0;
}

.line {
  width: 250px;
  height: 5px;
  border: none;
}
.line_left {
  background: linear-gradient(-90deg, #04549B 55.51%, rgba(4, 84, 155, 0) 100%);
}
.line_right {
  background: linear-gradient(90deg, #04549B 55.51%, rgba(4, 84, 155, 0) 100%);
}

.arrow_box {
  width: 100%;
  position: absolute;
  height: 100px;
  top: 50%;
  z-index: 100;
}

.arrow {
  position: absolute;
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: white;
  text-align: center;
  font-size: 2em;
  cursor: pointer;
  line-height: 30px;
  z-index: 1000;
  top: 50%;
  transform: translate(0, -50%);
  transition: all 500ms;
}
.arrow > svg {
  width: 100px;
  height: 99px;
}
.arrow i {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
}
.arrow-right {
  right: 0;
}
.arrow-left {
  left: 0;
}

@media (max-width: 1399px) {
  .myimg {
    width: 100%;
    height: auto;
  }
  .numbers {
    position: absolute;
    bottom: -100px;
    left: 0%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .numbers_left {
    color: #04549B;
    text-align: center;
    font-family: "Comfortaa", sans-serif;
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.65px;
    margin-left: 50px;
    width: 40px !important;
  }
  .numbers_right {
    color: #04549B;
    text-align: center;
    font-family: "Comfortaa", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.85px;
    margin-right: 50px;
  }
  .numbers_line {
    width: 50px;
    height: 1px;
    background-color: #04549B;
    transform: rotate(110deg);
    margin: 0;
  }
  .line {
    width: 200px;
    height: 3px;
    border: none;
  }
  .line_left {
    background: linear-gradient(-90deg, #04549B 55.51%, rgba(4, 84, 155, 0) 100%);
  }
  .line_right {
    background: linear-gradient(90deg, #04549B 55.51%, rgba(4, 84, 155, 0) 100%);
  }
  .arrow_box {
    width: 100%;
    position: relative;
    height: 100px;
  }
  .arrow {
    position: absolute;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: white;
    text-align: center;
    font-size: 2em;
    cursor: pointer;
    line-height: 30px;
    z-index: 1000;
    top: 50%;
    transform: translate(0, -50%);
    transition: all 500ms;
  }
  .arrow > svg {
    width: 100px;
    height: 99px;
  }
  .arrow i {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute;
  }
  .arrow-right {
    right: 0;
  }
  .arrow-left {
    left: 0;
  }
}
@media (max-width: 767px) {
  .myimg {
    width: 100%;
    height: auto;
  }
  .numbers {
    position: absolute;
    bottom: -100px;
    left: 0%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .numbers_left {
    color: #04549B;
    text-align: center;
    font-family: "Comfortaa", sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.3px;
    margin-left: 20px;
    width: 40px !important;
  }
  .numbers_right {
    color: #04549B;
    text-align: center;
    font-family: "Comfortaa", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.7px;
    margin-right: 20px;
  }
  .numbers_line {
    width: 50px;
    height: 1px;
    background-color: #04549B;
    transform: rotate(110deg);
    margin: 0;
  }
  .line {
    width: 100px;
    height: 2px;
    border: none;
  }
  .line_left {
    background: linear-gradient(-90deg, #04549B 55.51%, rgba(4, 84, 155, 0) 100%);
  }
  .line_right {
    background: linear-gradient(90deg, #04549B 55.51%, rgba(4, 84, 155, 0) 100%);
  }
  .arrow_box {
    width: 100%;
    position: relative;
    height: 100px;
  }
  .arrow {
    position: absolute;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: white;
    text-align: center;
    font-size: 2em;
    cursor: pointer;
    line-height: 30px;
    z-index: 1000;
    top: 50%;
    transform: translate(0, -50%);
    transition: all 500ms;
  }
  .arrow > svg {
    width: 100px;
    height: 99px;
  }
  .arrow i {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute;
  }
  .arrow-right {
    right: 0;
  }
  .arrow-left {
    left: 0;
  }
}

