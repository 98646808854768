.btn {
  width: 180px;
  height: 62.171px;
  flex-shrink: 0;
  border-radius: 33.469px;
  background: linear-gradient(94deg, #E1F4FE 11.04%, #CFE8FF 100.02%);
  box-shadow: 0px 3.21817px 0px 0px #89C1F2;
  border: none;
  color: #04549B;
  text-align: center;
  font-family: "Comfortaa", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.btn6 {
  width: 250px;
}
.btn_active {
  width: 180px;
  height: 62.171px;
  flex-shrink: 0;
  border-radius: 33.469px;
  background: linear-gradient(94deg, #9DDCFF 11.04%, #ABD8FF 100.02%);
  box-shadow: 0px 3.21817px 0px 0px #43A7FF;
  color: #FFF;
  text-align: center;
  font-family: "Comfortaa", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.btn_active6 {
  width: 250px;
}

@media (max-width: 1399px) {
  .btn {
    width: 96.43px;
    height: 33.455px;
    border-radius: 18.01px;
    font-size: 11px;
  }
  .btn6 {
    width: 131.197px;
  }
  .btn_active {
    width: 96.43px;
    height: 33.455px;
    border-radius: 18.01px;
    font-size: 11px;
  }
  .btn_active6 {
    width: 131.197px;
  }
}
@media (max-width: 767px) {
  .btn {
    width: 101.962px;
    height: 35.374px;
    border-radius: 19.043px;
    font-size: 10px;
    margin-bottom: 15px;
  }
  .btn6 {
    width: 150px;
  }
  .btn_active {
    width: 101.962px;
    height: 35.374px;
    border-radius: 19.043px;
    font-size: 12px;
  }
  .btn_active6 {
    width: 150px;
  }
}

