.wrap
  background-color: #c9edfc
.box
  display: flex
  align-items: center
  flex-direction: column
  padding-top: 76px
  padding-bottom: 180px
  &_title
    color: #1A92FD
    text-align: center
    font-family: 'Druk Cyr', sans-serif
    font-size: 109px
    font-style: normal
    font-weight: 500
    line-height: normal
    letter-spacing: 5.45px
    margin-bottom: 20px
  &_desc
    color: #04549B
    text-align: center
    font-family: 'Comfortaa', sans-serif
    font-size: 25px
    font-style: normal
    font-weight: 400
    line-height: normal
    margin-bottom: 63px
  &_btnsBox
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    margin-bottom: 65px
  &_form
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    &__inp
      width: 416.528px
      height: 84.113px
      flex-shrink: 0
      border-radius: 63.39px
      border: 2.438px solid #1A92FD
      background-color: transparent
      color: #848D95
      font-family: 'Comfortaa', sans-serif
      font-size: 31px
      font-style: normal
      font-weight: 400
      line-height: normal
      text-align: center
      &:focus
        border-color: #1A92FD
    &__btn
      width: 416.528px
      height: 84.113px
      flex-shrink: 0
      border-radius: 63.39px
      background: linear-gradient(94deg, #57C2FE 11.04%, #1A92FD 100.02%)
      box-shadow: 0px 6.09517px 0px 0px #1D96FF
      color: #FFF
      text-align: center
      font-family: 'Comfortaa', sans-serif
      font-size: 31px
      font-style: normal
      font-weight: 400
      line-height: normal
      border: none
      &:active
        transform: scale(0.95)
        box-shadow: 0px 2px 0px 0px #1D96FF
        background: linear-gradient(94deg, #1A92FD 11.04%, #57C2FE 100.02%)

@media (max-width: 1399px)

  .box
    padding-top: 58px
    padding-bottom: 67px
    &_title
      font-size: 59px
      letter-spacing: 2.95px
      margin-bottom: 10px
    &_desc
      font-size: 17px
      margin-bottom: 53px
    &_btnsBox
      display: flex
      align-items: center
      justify-content: space-between
      width: 100%
      margin-bottom: 35px
    &_form
      &__inp
        width: 223.691px
        height: 45.263px
        border-radius: 34.111px
        border: 1.312px solid #1A92FD
        font-size: 17px
      &__btn
        width: 223.691px
        height: 45.263px
        border-radius: 34.111px
        font-size: 17px

@media (max-width: 767px)

  .box
    padding-top: 50px
    padding-bottom: 60px
    &_title
      font-size: 50px
      letter-spacing: 2.5px
      margin-bottom: 20px
    &_desc
      font-size: 14px
      margin-bottom: 29px
    &_btnsBox
      display: flex
      align-items: center
      justify-content: space-evenly
      flex-wrap: wrap
      width: 100%
      margin-bottom: 15px
    &_form
      flex-direction: column
      &__inp
        width: 274.691px
        height: 45.263px
        border-radius: 34.111px
        border: 1.312px solid #1A92FD
        font-size: 17px
        margin-bottom: 20px
      &__btn
        width: 274.691px
        height: 47px
        border-radius: 35.75px
        font-size: 17px
