.reviews_bg_color {
  background: #C9EDFC;
  padding-bottom: 100px;
  position: relative;
}

.review_drops_img {
  position: absolute;
  width: 264px;
  height: 133px;
  top: 19%;
  left: 42%;
}

.review_drops_bg {
  position: absolute;
  width: 130px;
  height: 181px;
  top: 75%;
}

.my_card {
  border-radius: 39.009px;
  background: #EEFAFF !important;
  height: auto;
  width: 100%;
}

.testimonials_text {
  color: #04549B;
  text-align: center;
  font-family: Comfortaa, sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 30px;
}

.testimonials_name {
  color: #04549B;
  text-align: center;
  font-family: Comfortaa, sans-serif;
  font-size: 23.162px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 37px;
  padding-top: 30px;
}

.reviews_title {
  color: #1A92FD;
  text-align: center;
  font-family: "Druk Cyr", sans-serif;
  font-size: 109px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 5.45px;
  padding-top: 130px;
  margin-bottom: 128px;
}

.slide {
  opacity: 0.5 !important;
  transform: scale(0.7) !important;
}

.slide-active {
  opacity: 1 !important;
  transform: scale(1) !important;
}

.slick-list {
  margin: 0 -10px;
}

@media (max-width: 1320px) {
  .reviews_bg_color {
    padding-bottom: 50px;
  }
  .review_drops_img {
    top: 3%;
    left: 31%;
  }
  .review_drops_bg {
    position: absolute;
    width: 110px;
    height: 150px;
    top: 75%;
  }
  .reviews_title {
    font-size: 59px;
    padding-top: 50px;
    margin-bottom: 40px;
    letter-spacing: 2.5px;
  }
  .testimonials_name {
    font-size: 13px;
    margin-bottom: 20px;
    padding-top: 20px;
  }
  .testimonials_text {
    font-size: 10px;
    padding-bottom: 0px;
  }
  .slide {
    opacity: 0.5 !important;
    transform: scale(0.7) !important;
  }
  .slide-active {
    opacity: 1 !important;
    transform: scale(1) !important;
  }
  .slick-list {
    margin: 0 -10px;
  }
}
@media (max-width: 720px) {
  .review_drops_img {
    top: 3%;
    left: 26%;
  }
  .testimonials_name {
    font-size: 15px;
    margin-bottom: 20px;
    padding-top: 20px;
  }
  .slide {
    opacity: 1 !important;
    transform: scale(1) !important;
  }
  .slide-active {
    opacity: 1 !important;
    transform: scale(1) !important;
  }
  .slick-list {
    margin: 0 -10px;
  }
  .my_card {
    width: 80%;
    margin: auto;
  }
}

