.box {
  background: #C9EDFC;
  position: relative;
  padding-top: 190px;
  padding-bottom: 140px;
}
.box_photo {
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 0;
  bottom: 0;
}

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrap_title {
  color: #1A92FD;
  text-align: center;
  font-family: "Druk Cyr", sans-serif;
  font-size: 109px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 5.45px;
  z-index: 1;
  margin-bottom: 70px;
}
.wrap_box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 50px;
  row-gap: 55px;
  margin-bottom: 100px;
}
.wrap_boxForNot {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.wrap_boxForNot_not {
  color: #04549B;
  font-family: "Comfortaa", sans-serif;
  font-size: 24.381px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 1;
}

@media (max-width: 1399px) {
  .box {
    padding-top: 79px;
    padding-bottom: 100px;
  }
  .box_photo {
    bottom: 20%;
  }
  .wrap_title {
    font-size: 59px;
    letter-spacing: 2.95px;
    margin-bottom: 51px;
  }
  .wrap_box {
    grid-template-columns: 1fr 1fr;
    column-gap: 33px;
    row-gap: 38px;
  }
  .wrap_boxForNot {
    align-items: center;
  }
  .wrap_boxForNot_not {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .box {
    padding-top: 25px;
    padding-bottom: 53px;
  }
  .box_photo {
    bottom: 0;
    width: 100%;
    height: 2192px;
  }
  .wrap_title {
    font-size: 50px;
    letter-spacing: 2.5px;
    margin-bottom: 46px;
  }
  .wrap_box {
    grid-template-columns: 1fr;
    column-gap: 0px;
    row-gap: 38px;
    margin-bottom: 30px;
  }
  .wrap_boxForNot {
    align-items: center;
  }
  .wrap_boxForNot_not {
    font-size: 12px;
  }
}

