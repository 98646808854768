.teachersPopup-title {
  color: #1A92FD;
  text-align: center;
  font-family: Druk Cyr, serif;
  font-size: 90px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 4.5px;
  margin-bottom: 44px;
}

.form-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.modal-content {
  width: 1217px !important;
  height: 408px !important;
}

.modalTeacher {
  background: #C9EDFC !important;
  width: 100%;
  height: 100%;
}

.input, .send-form, .formTeacher-file {
  width: 23%;
  height: 57px;
  margin: 0 9px 33px 9px;
  border-radius: 42.957px;
  background: inherit;
  padding: 0 10px;
  border: 1px solid #1A92FD;
  color: #848D95;
  font-family: Comfortaa, serif;
  font-size: 20.652px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.formTeacher-file {
  display: flex;
  align-items: center;
  position: relative;
}
.formTeacher-file__img {
  position: absolute;
  top: 5px;
  right: 10px;
  margin: auto;
  width: 46px !important;
  height: 58px !important;
}

.send-form {
  border-radius: 43.003px;
  background: linear-gradient(94deg, #57C2FE 11.04%, #1A92FD 100.02%);
  color: #FFF;
  font-size: 20.674px;
}

input[type=file] {
  display: none;
}

@media (max-width: 1399px) {
  .teachersPopup-title {
    font-size: 51px;
    margin: 18px auto 25px auto;
    letter-spacing: 2.55px;
  }
  .modal-content {
    width: 696px !important;
    height: 233.334px !important;
  }
  .input, .send-form, .formTeacher-file {
    width: 22%;
    font-size: 12px;
    height: 31px;
    margin: 0 5px 18px 9px;
    padding: 0 10px;
  }
  .send-form {
    font-size: 12px;
    border-radius: 24.593px;
  }
  .formTeacher-file__img {
    top: 0;
    right: 5px;
    width: 26.307px !important;
    height: 33.17px !important;
  }
}
@media (max-width: 767px) {
  .teachersPopup-title {
    font-size: 38px;
    letter-spacing: 1.9px;
    margin: 30px auto;
  }
  .modal-content {
    width: 276px !important;
    height: 573.467px !important;
  }
  .input, .send-form, .formTeacher-file {
    width: 80%;
    font-size: 13px;
    height: 43.7px;
    margin: 6px 0;
    padding: 0 16px;
  }
  .formTeacher-file__img {
    width: 31.266px !important;
    height: 39.422px !important;
    top: 5px;
    right: 10px;
  }
  .send-form {
    border-radius: 32.969px;
  }
  .form-wrapper {
    flex-direction: column;
  }
}

