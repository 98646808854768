.faq {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #C9EDFC;
  padding: 150px 0;
}
.faq-title {
  color: #1A92FD;
  text-align: center;
  font-family: Druk Cyr, serif;
  font-size: 109px;
  font-style: normal;
  font-weight: 500;
  margin: auto;
  line-height: normal;
  letter-spacing: 5.45px;
  margin-bottom: 62px;
}
.faq-wrapper {
  position: relative;
}
.faq-raindropOne {
  width: 76px;
  height: 76px;
  background-image: url("../../Assets/Images/FaqRainDropOne.webp");
  background-size: cover;
  position: absolute;
  top: 6%;
  left: 11%;
}
.faq-raindropTwo {
  background-image: url("../../Assets/Images/FaqRainDropTwo.webp");
  width: 57px;
  height: 47px;
  background-size: cover;
  position: absolute;
  top: 40%;
  left: 13%;
}
.faq-raindropThree {
  width: 190px;
  height: 258px;
  background-image: url("../../Assets/Images/FaqRainDropThree.webp");
  background-size: contain;
  position: absolute;
  right: 0;
  top: 55%;
}

@media (max-width: 1399px) {
  .faq {
    padding: 90px 0;
  }
  .faq-title {
    position: relative;
    width: 530px;
    font-size: 59px;
    margin-bottom: 40px;
    letter-spacing: 2.95px;
  }
  .faq-raindropOne {
    top: 25%;
    left: -7%;
  }
  .faq-raindropTwo {
    left: 3%;
  }
  .faq-raindropThree {
    width: 98px;
    height: 142px;
    right: -5%;
    top: 30%;
  }
}
@media (max-width: 767px) {
  .faq {
    padding: 70px 0;
  }
  .faq-title {
    width: 90%;
    font-size: 50px;
  }
  .faq-raindropOne {
    display: none;
  }
  .faq-raindropTwo {
    top: 15%;
    left: 9%;
  }
  .faq-raindropThree {
    width: 52px;
    height: 74px;
    right: 3%;
    top: -2%;
  }
}

