.wrap {
  width: 100%;
  height: 211px;
  background: #1a92fd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.wrap_title {
  color: #fff;
  text-align: center;
  font-family: 'Gilroy', sans-serif;
  font-size: 34.773px;
  font-style: normal;
  font-weight: 300;
  line-height: 115.1%;
}
.wrap_backImg {
  position: absolute;
}
.wrap_backImg__first {
  bottom: 0;
  left: 15%;
}
.wrap_backImg__second {
  bottom: 0;
  left: 65%;
}
.wrap_backImg__third {
  top: 0;
  left: 80%;
}

@media (max-width: 1399px) {
  .wrap {
    width: 100%;
    height: 152px;
  }
  .wrap_title {
    font-size: 15px;
    line-height: 18.28px;
  }
  .wrap_backImg__first {
    width: 121.81px;
    height: 121.64px;
    bottom: 0;
    left: 15%;
  }
  .wrap_backImg__second {
    width: 70.23px;
    height: 70.13px;
    bottom: 0;
    left: 65%;
  }
  .wrap_backImg__third {
    width: 79.11px;
    height: 79px;
    top: 0;
    left: 80%;
  }
}
@media (max-width: 767px) {
  .wrap {
    width: 100%;
    height: 149px;
  }
  .wrap_title {
    font-size: 18.71px;
    line-height: 21.54px;
  }
  .wrap_backImg__first {
    width: 91.7px;
    height: 91.56px;
    bottom: 0;
    left: 0;
  }
  .wrap_backImg__second {
    width: 52.87px;
    height: 52.79px;
    bottom: 0;
    left: 80%;
  }
  .wrap_backImg__third {
    width: 59.56px;
    height: 59.47px;
    top: 0;
    left: 80%;
  }
  .logo {
    width: 124.35px;
    height: 41.95px;
  }
}

