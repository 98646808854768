.box {
  width: 398.581px;
  height: auto;
  flex-shrink: 0;
  padding: 11px 15px 24px 15px;
  border: none;
  border-radius: 8.533px;
  background: #FFF;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.box_img {
  width: 369.238px;
  height: 230.397px;
  flex-shrink: 0;
  border-radius: 6.095px;
  margin-bottom: 20px;
}
.box_title {
  color: #1A92FD;
  text-align: center;
  font-family: "Druk Cyr", sans-serif;
  font-size: 55px;
  font-style: normal;
  font-weight: 500;
  line-height: 82.1%;
  letter-spacing: 2.75px;
  margin-bottom: 36px;
  width: 369.238px;
}
.box_title6 {
  width: 369.238px;
}
.box_title5 {
  width: 200px;
}
.box_title4 {
  width: 200px;
}
.box_description {
  color: #04549B;
  text-align: center;
  font-family: "Comfortaa", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 43px;
}
.box_btnBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.box_btnBox__first {
  width: 49%;
  height: 39px;
  flex-shrink: 0;
  border: none;
  border-radius: 29.076px;
  background: linear-gradient(94deg, #E1F4FE 11.04%, #CFE8FF 100.02%);
  box-shadow: 0px 2.79579px 0px 0px #89C1F2;
  color: #04549B;
  text-align: center;
  font-family: "Comfortaa", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.box_btnBox__first:active {
  color: #FFF;
}
.box_btnBox__second {
  border: none;
  width: 100%;
  height: 39px;
  flex-shrink: 0;
  border-radius: 29.076px;
  background: linear-gradient(94deg, #57C2FE 11.04%, #1A92FD 100.02%);
  box-shadow: 0px 2.79579px 0px 0px #1D96FF;
  color: #FFF;
  text-align: center;
  font-family: "Comfortaa", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}
.box_btnBox__second_link {
  width: 49%;
}
.box_btnBox__second:active {
  color: #04549B;
}

@media (max-width: 1399px) {
  .box {
    width: 279px;
    padding: 7px 10px 16px 10px;
  }
  .box_img {
    width: 258px;
    height: 161px;
    margin-bottom: 13px;
  }
  .box_title {
    font-size: 39px;
    line-height: normal;
    letter-spacing: 1.95px;
    margin-bottom: 25px;
    width: 143px;
  }
  .box_title6 {
    width: 258.79px;
  }
  .box_title5 {
    width: 150px;
  }
  .box_title4 {
    width: 112px;
  }
  .box_description {
    font-size: 13px;
    margin-bottom: 30px;
  }
  .box_btnBox__first {
    height: 27.42px;
    font-size: 13px;
  }
  .box_btnBox__second {
    height: 27.42px;
    font-size: 13px;
  }
  .box_btnBox__second_link {
    width: 49%;
  }
}
@media (max-width: 767px) {
  .box {
    width: 235.88px;
    padding: 7px 10px 16px 10px;
  }
  .box_img {
    width: 218.51px;
    height: 136.75px;
    margin-bottom: 10px;
  }
  .box_title {
    font-size: 28px;
    line-height: 82.1%;
    letter-spacing: 1.95px;
    margin-bottom: 14px;
    width: 112px;
  }
  .box_title6 {
    width: 218.51px;
  }
  .box_title5 {
    width: 100px;
  }
  .box_title4 {
    width: 100px;
  }
  .box_description {
    font-size: 11px;
    margin-bottom: 25px;
  }
  .box_btnBox__first {
    height: 23.15px;
    font-size: 11px;
  }
  .box_btnBox__second {
    height: 23.15px;
    font-size: 11px;
  }
  .box_btnBox__second_link {
    width: 49%;
  }
}

