.wrapN
  display: none

.wrap
  display: flex
  justify-content: center

.backdrop
  position: fixed
  top: -100%
  width: 100%
  height: 1000%
  background-color: #212121
  opacity: 0.5
  z-index: 1000
  &_modal
    position: fixed
    top: 3%
    z-index: 1001
    width: 80%
    background-color: rgba(201, 237, 252, 1)
    padding: 30px 40px 30px 40px
    display: flex
    flex-direction: column
    align-items: flex-start
    &__close
      position: relative
      width: 100%
      &_btn
        position: absolute
        right: -10px
        top: -20px
        cursor: pointer
    &__alert
      color: #04549B
      font-family: 'Comfortaa', sans-serif
      font-size: 16px
      font-style: normal
      font-weight: 300
      line-height: normal
      margin-bottom: 24px
      &>span
        font-weight: 700
    &__box
      margin-bottom: 15px
      &__title
        color: #1A92FD
        font-family: 'Druk Cyr', sans-serif
        font-size: 34px
        font-style: normal
        font-weight: 500
        line-height: normal
        letter-spacing: 2.15px
        margin-bottom: 15px
      &__desc
        color: #04549B
        font-family: 'Comfortaa', sans-serif
        font-size: 14px
        font-style: normal
        font-weight: 300
        line-height: normal
      &__mini
        display: flex
        align-items: center
        margin-bottom: 10px
        &>img
          width: 25px
          height: 25px
        &__text
          color: #04549B
          font-family: 'Comfortaa', sans-serif
          font-size: 14px
          font-style: normal
          font-weight: 300
          line-height: normal
          margin: 0
          margin-left: 14px


@media (max-width: 767px)

  .wrapN
    display: none

  .wrap
    display: flex
    justify-content: center

  .backdrop
    position: fixed
    top: -100%
    width: 100%
    height: 1000%
    background-color: #212121
    opacity: 0.5
    z-index: 1000
    &_modal
      position: fixed
      top: 7%
      z-index: 1001
      width: 80%
      background-color: rgba(201, 237, 252, 1)
      padding: 27px 40px 19px 29px
      display: flex
      flex-direction: column
      align-items: flex-start
      &__close
        position: relative
        width: 100%
        &_btn
          position: absolute
          right: -20px
          top: -10px
          cursor: pointer
          width: 16px
          height: 16px
      &__alert
        color: #04549B
        font-family: 'Comfortaa', sans-serif
        font-size: 12px
        font-style: normal
        font-weight: 300
        line-height: normal
        margin-bottom: 5px
        &>span
          font-weight: 700
      &__box
        margin-bottom: 10px
        &__title
          color: #1A92FD
          font-family: 'Druk Cyr', sans-serif
          font-size: 21px
          font-style: normal
          font-weight: 500
          line-height: normal
          letter-spacing: 2.15px
          margin-bottom: 5px
        &__desc
          color: #04549B
          font-family: 'Comfortaa', sans-serif
          font-size: 8px
          font-style: normal
          font-weight: 300
          line-height: normal
        &__mini
          display: flex
          align-items: center
          margin-bottom: 10px
          &>img
            width: 13.132px
            height: 12.344px
          &__text
            color: #04549B
            font-family: 'Comfortaa', sans-serif
            font-size: 8px
            font-style: normal
            font-weight: 300
            line-height: normal
            margin: 0
            margin-left: 14px

